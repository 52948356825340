import { Injectable } from '@angular/core';
import { Viewer, ViewerStatus } from '../classes/viewer';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Watchperiods } from '../classes/watchperiods';
import '../extensions/string.extensions';

@Injectable({
  providedIn: 'root'
})
export class TwitchService {
  currentViewers: Viewer[] = [];
  uniqueViewers: Viewer[] = [];
  coloredNames: string;
  ghostedNames: string;
  englishNames: string;
  currentEnglishViewersNumber = 0;

  clientId = 'gs4g5q2d6gsnfyvpuulsswlfr8dxe2';
  scope: string = encodeURI('moderator:read:chatters');
  twitchAuthLink: string;
  state: string;
  accessToken: string;
  userId: string;
  intervalID: number;

  constructor(private http: HttpClient) {
    const params = new HttpParams()
      .set('response_type', 'token')
      .set('client_id', `${this.clientId}`)
      .set('redirect_uri', `${document.baseURI}`)
      .set('scope', `${this.scope}`)
      .set('state', `${this.makeRandomState(32)}`);

    this.twitchAuthLink = `https://id.twitch.tv/oauth2/authorize?${params.toString()}`;

    // const rawUniqueViewers = localStorage.getItem('uniqueViewers');
    const rawUniqueViewers = '[]'; // temporaire

    this.uniqueViewers = rawUniqueViewers ? JSON.parse(rawUniqueViewers).map(viewer => new Viewer(viewer)) : [];
  }

  static msToTime(duration) {
    const seconds = parseInt('' + (duration / 1000) % 60, 10);
    const minutes = parseInt('' + (duration / (1000 * 60)) % 60, 10);
    const hours = parseInt('' + (duration / (1000 * 60 * 60)) % 24, 10);

    const hoursString = (hours < 10) ? '0' + hours : hours;
    const minutesString = (minutes < 10) ? '0' + minutes : minutes;
    const secondsString = (seconds < 10) ? '0' + seconds : seconds;

    return `${hoursString}h${minutesString}m${secondsString}s`;
  }

  static timeToMs(time: string) {
    const pattern = /((?<hours>[0-9]{2,})h)((?<minutes>[0-9]{2,})m)((?<seconds>[0-9]{2,})s)/;

    if (time && pattern.test(time)) {
      const parties = time.match(pattern).groups;

      const hoursToMs = parseInt(parties.hours, 10) * 1000 * 60 * 60;
      const minutesToMs = parseInt(parties.minutes, 10) * 1000 * 60;
      const secondsToMs = parseInt(parties.seconds, 10) * 1000;

      return hoursToMs + minutesToMs + secondsToMs;
    }

    return 0;
  }

  toggleEnglish(viewer: Viewer): false {
    if (viewer.english) {
      this.englishNames = this.englishNames.replace(`${viewer.name}\n`, '');
      this.currentEnglishViewersNumber--;
    } else {
      this.englishNames += `${viewer.name}\n`;
      this.currentEnglishViewersNumber++;
    }
    viewer.english = !viewer.english;

    this.flagNames();

    return false;
  }

  flagNames(): void {
    const lists = [this.currentViewers, this.uniqueViewers];
    for (const list of lists) {
      for (const viewer of list) {
        viewer.english = this.englishNames.containsLine(viewer.name);
      }
    }
    localStorage.setItem('options.englishNames', this.englishNames);
  }

  colorMe(viewer: Viewer): void {
    switch (viewer.color) {
      case ViewerStatus.HIGLIGHTED:
        this.coloredNames = this.coloredNames.replace(`${viewer.name}\n`, '');
        this.ghostedNames += `${viewer.name}\n`;
        break;
      case ViewerStatus.GHOSTED:
        this.ghostedNames = this.ghostedNames.replace(`${viewer.name}\n`, '');
        break;
      default:
        this.coloredNames += `${viewer.name}\n`;
        break;
    }
    this.colorNames();
  }

  colorNames(): void {
    const lists = [this.currentViewers, this.uniqueViewers];
    for (const list of lists) {
      for (const viewer of list) {
        if (this.coloredNames.containsLine(viewer.name.toLowerCase())) {
          this.coloredNames.replace(`${viewer.name.toLowerCase()}\n`, `${viewer.name}\n`);
        }

        if (this.coloredNames.containsLine(viewer.name)) {
          viewer.color = ViewerStatus.HIGLIGHTED;
        } else if (this.ghostedNames.containsLine(viewer.name)) {
          viewer.color = ViewerStatus.GHOSTED;
        } else {
          viewer.color = null;
        }
      }
    }
    localStorage.setItem('options.coloredNames', this.coloredNames);
    localStorage.setItem('options.ghostedNames', this.ghostedNames);
  }

  refreshData(): void {
    if (!this.accessToken || !this.userId) {
      return;
    }
    const rawData = this.http.get(
      `https://api.twitch.tv/helix/chat/chatters?broadcaster_id=95102034&moderator_id=${this.userId}&first=1000`,
      {
        headers: new HttpHeaders(
          {
            'Client-ID': this.clientId,
            Accept: 'application/vnd.twitchtv.v5+json',
            Authorization: `Bearer ${this.accessToken}`
          }
        )
      }
    );
    rawData.subscribe(
      (rawViewers: {data: TwitchChatter[], pagination: {cursor: string}, total: number}) => {
        this.currentViewers = [];
        for (const rawViewer of rawViewers.data) {
          const viewerName = rawViewer.user_login;
          let viewer = this.uniqueViewers.find((element) => element.name === viewerName);
          if (viewer === undefined) {
            viewer = new Viewer(
              {
                _name: viewerName,
                _displayName: rawViewer.user_name,
                _watchTime: TwitchService.msToTime(0),
                _currentWatchTime: TwitchService.msToTime(0),
                _watchPeriods: [
                  new Watchperiods(
                    {
                      _start: new Date(),
                      _duration: TwitchService.msToTime(0)
                    }
                  )
                ],
                _twitch_id: rawViewer.user_id
              }
            );

            this.uniqueViewers.push(viewer);
          } else {
            let totalWatchTimeMs = 0;
            let newPeriod = true;
            for (const watchPeriod of viewer.watchPeriods) {
              let end = new Date();
              if (watchPeriod.end !== undefined) {
                end = watchPeriod.end;
              } else {
                newPeriod = false;
              }
              const periodDuration = end.getTime() - watchPeriod.start.getTime();
              totalWatchTimeMs += periodDuration;
              watchPeriod.duration = TwitchService.msToTime(periodDuration);
              viewer.watchTime = TwitchService.msToTime(totalWatchTimeMs);
              viewer.currentWatchTime = TwitchService.msToTime(periodDuration);
            }
            if (newPeriod) {
              const watchPeriod = new Watchperiods();
              watchPeriod.start = new Date();
              watchPeriod.duration = TwitchService.msToTime(0);
              viewer.watchPeriods.push(watchPeriod);
            }
          }
          this.currentViewers.push(viewer);
          this.uniqueViewers.sort((a, b) => (a.name >= b.name ? 1 : -1));
          this.currentViewers.sort((a, b) => (a.name >= b.name ? 1 : -1));
        }

        for (const viewer of this.uniqueViewers) {
          const period = viewer.watchPeriods.splice(-1, 1)[0];
          if (this.currentViewers.find((element) => element.name === viewer.name) === undefined && period.end === undefined) {
            period.end = new Date();
            let watchTimeMs = period.end.getTime() - period.start.getTime() - 15000;
            if (watchTimeMs + 1000 > TwitchService.timeToMs(period.duration)) {
              watchTimeMs = TwitchService.timeToMs(period.duration);
              period.end.setTime(period.start.getTime() + watchTimeMs);
            }
            period.duration = TwitchService.msToTime(watchTimeMs);
          }
          viewer.watchPeriods.push(period);
        }

        this.colorNames();
        this.flagNames();
        this.currentEnglishViewersNumber = this.currentViewers.filter(viewer => viewer.english).length;
        /*this.currentEnglishViewersNumber = this.currentViewers.reduce((acc, viewer): number => {
          return acc + (viewer.english ? 1 : 0);
        }, 0);*/
        // localStorage.setItem('uniqueViewers', JSON.stringify(this.uniqueViewers));
      },
      (error) => {
        clearTimeout(this.intervalID);
        this.accessToken = null;
      }
    );
  }

  getCurrentUser(): void {
    const rawData = this.http.get(
      `https://api.twitch.tv/helix/users`,
      {
        headers: new HttpHeaders(
          {
            'Client-ID': this.clientId,
            Accept: 'application/vnd.twitchtv.v5+json',
            Authorization: `Bearer ${this.accessToken}`
          }
        )
      }
    );
    rawData.subscribe((data: { data: TwitchUser[] }) => {
      this.userId = data.data[0].id;
      this.refreshData();
    });
  }

  makeRandomState(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    this.state = result;
    return result;
  }

  saveState() {
    localStorage.setItem('randState', this.state);
  }

  async validateAccessToken(accessToken: string): Promise<any> {
    const rawData = this.http.get(
      `https://id.twitch.tv/oauth2/validate`,
      {
        headers: new HttpHeaders(
          {
            Authorization: `Bearer ${accessToken}`
          }
        )
      }
    );

    return rawData.toPromise();
  }
}

export interface TwitchUser {
  id: string;
  login: string;
  display_name: string;
  type: string;
  broadcaster_type: string;
  description: string;
  profile_image_url: URL;
  offline_image_url: URL;
  view_count: number;
  created_at: Date;
}

export interface TwitchChatter {
  user_id: string;
  user_login: string;
  user_name: string;
}
