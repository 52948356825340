import { Component, OnInit } from '@angular/core';
import { TwitchService } from '../../../services/twitch.service';
import { ViewerStatus } from '../../../classes/viewer';

@Component({
  selector: 'app-unique',
  templateUrl: './unique.component.html',
  styleUrls: ['./unique.component.scss']
})
export class UniqueComponent implements OnInit {
  ViewerStatus = ViewerStatus;

  constructor(public twitchService: TwitchService) { }

  ngOnInit() {
  }

}
