import { Component, OnInit } from '@angular/core';
import { TwitchService } from '../../../services/twitch.service';
import { ViewerStatus } from '../../../classes/viewer';

@Component({
    selector: 'app-current',
    templateUrl: './current.component.html',
    styleUrls: ['./current.component.scss']
})
export class CurrentComponent implements OnInit {
    ViewerStatus = ViewerStatus;

    constructor(public twitchService: TwitchService) { }

    ngOnInit() {
    }

}
