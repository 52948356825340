export class Watchperiods {
  private _start: Date;
  private _end: Date = undefined;
  private _duration: string;

  constructor({_start = null,
                _end = undefined,
                _duration = null} = {}) {
    this.start = new Date(_start);
    this.end = _end ? new Date(_end) : undefined;
    this.duration = _duration;
  }

  get start(): Date {
    return this._start;
  }

  set start(newStart: Date) {
    this._start = newStart;
  }

  get end(): Date {
    return this._end;
  }

  set end(newEnd: Date) {
    this._end = newEnd;
  }

  get duration(): string {
    return this._duration;
  }

  set duration(newDuration: string) {
    this._duration = newDuration;
  }
}
