import { Watchperiods } from './watchperiods';

export class Viewer {
  private _name: string;
  private _displayName: string;
  private _watchTime: string;
  private _currentWatchTime: string;
  private _watchPeriods: Watchperiods[];
  private _color: ViewerStatus = null;
  private _english = false;
  private _twitch_id: number = null;

  constructor({
                _name = null,
                _displayName = null,
                _watchTime = null,
                _currentWatchTime = null,
                _watchPeriods = [],
                _color = null,
                _english = false,
                _twitch_id = null,
              } = {}
  ) {
    this.name = _name;
    this.displayName = _displayName;
    this.watchTime = _watchTime;
    this.currentWatchTime = _currentWatchTime;
    this.watchPeriods = _watchPeriods.map((watchPeriod) => new Watchperiods(watchPeriod));
    this.color = _color;
    this.english = _english;
    this.twitch_id = _twitch_id;
  }

  get name(): string {
    return this._name;
  }

  set name(newName: string) {
    this._name = newName;
  }

  get displayName(): string {
    return this._displayName;
  }

  set displayName(newDisplayName: string) {
    this._displayName = newDisplayName;
  }

  get watchTime(): string {
    return this._watchTime;
  }

  set watchTime(newWatchTime: string) {
    this._watchTime = newWatchTime;
  }

  get currentWatchTime(): string {
    return this._currentWatchTime;
  }

  set currentWatchTime(newCurrentWatchTime: string) {
    this._currentWatchTime = newCurrentWatchTime;
  }

  get watchPeriods(): Watchperiods[] {
    return this._watchPeriods;
  }

  set watchPeriods(newWatchPeriods: Watchperiods[]) {
    this._watchPeriods = newWatchPeriods;
  }

  get color(): ViewerStatus {
    return this._color;
  }

  set color(value: ViewerStatus) {
    this._color = value;
  }

  get english(): boolean {
    return this._english;
  }

  set english(value: boolean) {
    this._english = value;
  }

  get twitch_id(): number {
    return this._twitch_id;
  }

  set twitch_id(value: number) {
    this._twitch_id = value;
  }
}

export enum ViewerStatus {
  HIGLIGHTED = '#FF1127',
  GHOSTED = '#DAD8DE55',
}
