import { Component, OnInit } from '@angular/core';
import { TwitchService } from './services/twitch.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(public twitchService: TwitchService, route: ActivatedRoute) {
    if (document.location.hash) {
      const parsedHashParams = this.parseParams(document.location.hash);

      if (
        parsedHashParams.hasOwnProperty('access_token')
        && parsedHashParams.hasOwnProperty('state')
        && localStorage.getItem('randState') === parsedHashParams.state
      ) {
        twitchService.validateAccessToken(parsedHashParams.access_token).then((data) => {
          twitchService.accessToken = parsedHashParams.access_token;
          this.twitchService.getCurrentUser();
          localStorage.setItem('twitchAccessToken', twitchService.accessToken);
        });
        localStorage.removeItem('randState');
        document.location.hash = '';
      }
    } else {
      const accessToken = localStorage.getItem('twitchAccessToken');
      if (accessToken) {
        twitchService.validateAccessToken(accessToken).then((data) => {
          twitchService.accessToken = accessToken;
          this.twitchService.getCurrentUser();
        });
      }
    }
  }

  ngOnInit() {
    if (this.twitchService.accessToken && this.twitchService.userId) {
      this.twitchService.refreshData();
    }
    this.twitchService.intervalID = setInterval(() => {
      this.twitchService.refreshData();
    }, 15000);
  }

  parseParams(str): any {
    if (str[0] === '#') {
      str = str.substr(1);
    }
    const pieces = str.split('&');
    const data = {};
    let i;
    let parts;
    // process each query pair
    for (i = 0; i < pieces.length; i++) {
      parts = pieces[i].split('=');
      if (parts.length < 2) {
        parts.push('');
      }
      data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    }
    return data;
  }
}
