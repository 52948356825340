import { Component, OnInit } from '@angular/core';
import { TwitchService } from '../../services/twitch.service';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit {

  constructor(public twitchService: TwitchService) { }

  ngOnInit() {
    this.twitchService.coloredNames = localStorage.getItem('options.coloredNames');
    this.twitchService.ghostedNames = localStorage.getItem('options.ghostedNames');
    this.twitchService.englishNames = localStorage.getItem('options.englishNames');
    this.twitchService.colorNames();
    this.twitchService.flagNames();
  }

  onNamesEnter(type: string) {switch (type) {
    case 'colored':
    case 'ghosted':
      this.twitchService.colorNames();
      break;
    case 'english':
      this.twitchService.flagNames();
      break;
  }
  }

  onNamesChange(type: string) {
    switch (type) {
      case 'colored':
        if (this.twitchService.coloredNames === '') {
          localStorage.setItem('options.coloredNames', this.twitchService.coloredNames);
        }
        break;
      case 'ghosted':
        if (this.twitchService.ghostedNames === '') {
          localStorage.setItem('options.ghostedNames', this.twitchService.ghostedNames);
        }
        break;
      case 'english':
        if (this.twitchService.englishNames === '') {
          localStorage.setItem('options.englishNames', this.twitchService.englishNames);
        }
        break;
    }
    this.twitchService.colorNames();
  }

  clearViewers() {
    if (
      confirm('Êtes-vous sûr(e) de vouloir effacer les listes de viewers et leurs périodes de présence ? Cette action est IRRÉVERSIBLE !')
    ) {
      localStorage.removeItem('uniqueViewers');

      this.twitchService.currentViewers = [];
      this.twitchService.uniqueViewers = [];

      this.twitchService.refreshData();
    }
  }

}
